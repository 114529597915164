@import url("https://use.typekit.net/lgc1bbw.css");
/* * {
  border: 1px solid green;
} */
body {
  margin: 0;
  font-family: montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
